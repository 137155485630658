import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpCallerService, ListResponseModel } from '@overflo1/frame-frontend';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MachineService extends HttpCallerService {

  constructor(
    protected override readonly http: HttpClient,
  ) {
    super(http, environment.backendBasePath);
  }

  async getMachineFamilies(filter?: string) {
    let query = '';
    if (filter) {
      query += `filter=${filter}`;
    }
    return await this.get<string[]>(`/machine/families?${query ?? ''}`);
  }

  async getMachineGroups(filter?: string) {
    let query = '';
    if (filter) {
      query += `filter=${filter}`;
    }
    return await this.get<string[]>(`/machine/groups?${query ?? ''}`);
  }
}
