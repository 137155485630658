import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FrameEntityHttpService, FrameEntityTableComponent, FrameEntityViewComponent, HasPermissionPipe, SnackBarService } from '@overflo1/frame-frontend';
import { BaseForm, DynamicFormComponent, ControlTypesEnum } from '@overflo-srl/dynamic-form';
import { ColumnModel } from '@overflo-srl/full-table';
import { MatTabsModule } from '@angular/material/tabs'; 
import { SCondition, QueryJoin } from "@nestjsx/crud-request";
import { MachineModel } from '../../models/machine.model';
import { MachineTranslations } from 'src/app/frame-entity-config/machine';
import { MatButtonModule } from '@angular/material/button';
import { MachineService } from '../../services/machine/machine.service';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MachineRequestModel } from '../../models/machine-request.model';
import { RequestTranslations } from 'src/app/frame-entity-config/request';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-machine-detail',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    FrameEntityViewComponent,
    FrameEntityTableComponent,
    MatTabsModule,
    DynamicFormComponent,
    RouterLink,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  providers: [
  ],
  templateUrl: './machine-detail.component.html',
  styleUrl: './machine-detail.component.scss'
})
export class MachineDetailComponent implements OnInit {

  readonly ControlTypesEnum = ControlTypesEnum;
  readonly MachineTranslations = MachineTranslations;
  entityName: string = 'machine';
  entityId: number;
  search: SCondition;
  join: QueryJoin[] = [{field: 'machine'}];
  forms: BaseForm[] = [];
  machine: MachineModel;
  submitting: boolean = false;
  @ViewChild(DynamicFormComponent) dynamicFormComponent: DynamicFormComponent;
  machineGroups: string[];
  groupControl: FormControl;
  filteredGroups: Observable<string[]>;
  columnList: ColumnModel[];

  constructor(
    private route: ActivatedRoute,
    private frameEntityHttpService: FrameEntityHttpService<MachineModel>,
    private snackBarService: SnackBarService,
    private router: Router,
    private machineService: MachineService,
    private hasPermissionPipe: HasPermissionPipe,
    private datePipe: DatePipe,
  ) {
  }

  ngOnInit(): void {
    this.prepareColumnList();
    this.route.params.subscribe(async (params) => {
      const entityId = params?.['entityId'];
      if (entityId && entityId !== 'create') {
        this.entityId = entityId;

        this.search = {
          'machine.id': {$eq: this.entityId}
        };

        this.machine = await this.frameEntityHttpService.getOne(this.entityName, this.entityId);
      }
      this.loadForms();
    });
  }

  async prepareColumnList() {
    const columns = [
      {
        def: "createdAt",
        name: RequestTranslations.fields.createdAt,
        value: (element: MachineRequestModel) => this.datePipe.transform(element.createdAt, environment.DATE_TIME_FORMAT),
        sort: true,
        type: "date",
      },
      {
        def: "uid",
        name: RequestTranslations.fields.uid,
        value: (element: MachineRequestModel) => 'test' + element.uid,
        sort: true,
        type: "string",
      },
      {
        def: "ipAddress",
        name: RequestTranslations.fields.ipAddress,
        value: (element: MachineRequestModel) => element.ipAddress,
        sort: true,
        type: "string",
      }
    ];

    this.columnList = columns;
  }

  async loadMachineGroups() {
    this.machineGroups = await this.machineService.getMachineGroups();
    this.groupControl = new FormControl(this.machine?.group || '');

    this.filteredGroups = this.groupControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.machineGroups.filter(option => option.toLowerCase().includes(filterValue));
  }

  async loadForms() {
    this.loadMachineGroups();

    const families = await this.machineService.getMachineFamilies();
    const familyOptions = families.map((family) => {
      return {
        key : family,
        value : family,
        mnemonicId: family,
        description: ''
      };
    });

    this.forms = [];

    const forms = [
      new BaseForm({
        key: 'uid',
        value: this.machine ? this.machine.uid : null,
        label: MachineTranslations.fields.uid,
        controlType: 'text',
        required: false,
        readonly: false,
        fullWidth: false,
      }),
      new BaseForm({
        key: 'description',
        value: this.machine ? this.machine.description : null,
        label: MachineTranslations.fields.description,
        controlType: 'text',
        required: false,
        readonly: false,
        fullWidth: false,
      }),
      new BaseForm({
        key: 'family',
        value: this.machine ? this.machine.family : null,
        label: MachineTranslations.fields.family,
        controlType: 'select',
        options: familyOptions,
        required: true,
        readonly: false,
        fullWidth: false,
      }),
      new BaseForm({
        key: 'params',
        value: this.machine ? this.machine.params : null,
        label: MachineTranslations.fields.params,
        controlType: 'text',
        required: false,
        readonly: false,
        fullWidth: false,
      }),
      new BaseForm({
        key: 'lampHours',
        value: this.machine ? this.machine.lampHours : null,
        label: MachineTranslations.fields.lampHours,
        controlType: 'text',
        type: 'number',
        required: false,
        readonly: true,
        fullWidth: false,
      }),
      new BaseForm({
        key: 'removed',
        value: this.machine ? this.machine.removed : false,
        label: MachineTranslations.fields.removed,
        controlType: 'checkbox',
        required: false,
        readonly: false,
        fullWidth: false,
      }),
    ];

    this.forms = this.forms.concat(forms);
  }

  confirm() {
    this.submitting = true;
    this.dynamicFormComponent.onSubmit();
    this.submitting = false;
  }

  async save(formResult: Partial<MachineModel>) {
    if (!this.submitting) {
      return;
    }

    const group = this.groupControl.value;

    if (!group) {
      this.snackBarService.openErrorSnackBar('Selezionare o inserire un gruppo');
      return;
    }

    const machineId = this.machine?.id as number;

    const data: Partial<MachineModel> = {
      id: machineId,
      removed: formResult.removed,
      uid: formResult.uid,
      description: formResult.description,
      family: formResult.family,
      params: formResult.params,
      group: group,
      lampHours: formResult.lampHours,
      version: this.machine?.['version'],
    };

    if (this.machine) {
      await this.frameEntityHttpService.updateOne(this.entityName, data as MachineModel);
      this.machine = await this.frameEntityHttpService.getOne(this.entityName, this.entityId);
      this.snackBarService.openInfoSnackBar(`Macchina aggiornata con successo`);
    } else {
      this.machine = await this.frameEntityHttpService.createOne(this.entityName, data as MachineModel);
      this.snackBarService.openInfoSnackBar(`Macchina creata con successo`);
      this.loadForms();
    }

    this.entityId = this.machine?.id;
    await this.router.navigate([`/frame/entity/machine/${this.machine?.id}`]);
  }
}
