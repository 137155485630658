export const MachineTranslations = {
    entityName: 'Macchina',
    fields: {
        uid: 'UID',
        group: 'Gruppo',
        description: 'Descrizione',
        removed: 'Rimossa',
        family: 'Famiglia',
        lampHours: 'H Lampada',
        periodFrom: 'Periodo inizio',
        periodTo: 'Periodo fine',
        params: 'Parametri',
    }
    
}

export const MachineFullTableConfig = {
    ignoreGeneratedColumns: false,
    allowedColumns: [
        'uid', 
        'group', 
        'description',
        'removed',
    ],
    columns: []
}

export const MachineDynamicFormConfig = {
    ignoreGeneratedForms: false,
    forms: [],
    readonly: false,
}