<mat-tab-group>
    <mat-tab label="Dettaglio">
        <div class="component-wrapper">
            <div class="middle-title">
                <div class="middle-title-title">{{entityId ? 'Dettaglio' : 'Creazione'}} {{MachineTranslations.entityName}} {{entityId}}</div>
                <div class="middle-title-actions">
                    <button mat-stroked-button [routerLink]="['/frame/entity/' + entityName]">Indietro</button>
                    <button type="submit" mat-raised-button color="primary" (click)="confirm()">Salva</button>
                </div>
            </div>
            <lib-dynamic-form 
              #dynamicForm 
              [forms]="forms" 
              (send)="save($event)" 
              [showButtonAndEmit]="false" 
              [showConfirmationDialog]="false"
            ></lib-dynamic-form>

            <form class="group-form" *ngIf="groupControl">
                <mat-label>Gruppo</mat-label>
                <mat-form-field class="full-width base-component">
                  <input type="text"
                         placeholder="Seleziona o inserisci"
                         matInput
                         [formControl]="groupControl"
                         [matAutocomplete]="auto">
                         <button
                            (click)="groupControl.reset()"
                            *ngIf="groupControl.value"
                            aria-label="Clear"
                            class="mat-mdc-select-search-clear"
                            mat-button
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    @for (group of filteredGroups | async; track group) {
                      <mat-option [value]="group">{{group}}</mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </form>
              
        </div>

    </mat-tab>
    <mat-tab label="Richieste">
        <div class="component-wrapper">
            <div class="middle-title">Richieste</div>
            <lib-frame-entity-table
                entityName="request"
                [actionsEnabled]="false"
                [search]="search"
                [join]="join"
                [columnList]="columnList"
            >
            </lib-frame-entity-table>
        </div>
    </mat-tab>
</mat-tab-group>
